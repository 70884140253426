<template>
  <div class="col-12 c g">
    <div class="card card-body">
        <div class="col-12">
            <button class="btn btn-primary" v-b-modal.new @click="admin='no'">
                <i class="fa fa-plus"></i>
                تحويل طالب للوكيل
            </button>
            &nbsp;
            <button class="btn btn-info" v-b-modal.new @click="admin='yes'">
                <i class="fa fa-plus"></i>
                تحويل طالب للموجه
            </button>
            <br><br>
        </div>
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        الطالب
                    </th>
                    <th>
                        الفصل
                    </th>
                    <th>
                        التاريخ
                    </th>
                    <th>
                        نوع المشكلة
                    </th>
                    <th>
                        تكرار المشكلة
                    </th>
                    <th>
                        وصف المشكلة
                    </th>
                    <th>
                        رأيك
                    </th>
                    <th>
                        الحالة	
                    </th>
                    <th>
                        الرد
                    </th>
                    <th>
                        حذف
                    </th>
                </thead>
                <tbody>
                    <tr v-for="item in items" :key="item._id">
                        <td>
                            {{ item.student_name }}
                        </td>
                        <td>
                            {{ item.classname }} - {{ item.classroom }}
                        </td>
                        <td>
                            {{ item.date }}
                        </td>
                        <td>
                            {{ item.type }}
                        </td>
                        <td>
                            {{ item.repeat }}
                        </td>
                        <td>
                            {{ item.details }}
                        </td>
                        <td>
                            {{ item.opinion }}
                        </td>
                        <td>
                            <span v-if="!item.send_to_second">
                                <span v-if="item.status" class="text-success">
                                    <i class="fa fa-eye"></i>
                                    تمت رؤيتها
                                </span>
                                <span v-if="!item.status" class="text-danger">
                                    <i class="fa fa-check"></i>
                                    تم الارسال
                                </span>
                            </span>
                            <span v-if="item.send_to_second">
                                <span v-if="item.second_viewed" class="text-success">
                                    <i class="fa fa-eye"></i>
                                    تمت رؤيتها
                                </span>
                                <span class="text-primary">
                                    <i class="fa fa-check"></i>
                                    تم تحويلها للموجه
                                </span>
                            </span>
                        </td>
                        <td>
                            {{ item.response }}
                        </td>
                        <td>
                            <button class="btn btn-outline-danger btn-sm" @click="remove(item._id)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <b-modal id="new" :title="'تحويل طالب ' + (admin ? 'للموجه' : 'للوكيل')" hide-footer>
        <div class="g">
            اختر الطلاب <input type="text" placeholder="ابحث هنا..." v-model="q" @change="getStudents()" style="border: none; border: 1px solid #999; padding: 5px"> <button class="btn btn-sm"><i class="fa fa-search"></i> بحث</button>
        </div>
        <div style="width: 100%; border: solid 2px #333; padding: 5px; overflow-y: scroll; height: 150px;">
            <div class="form-check" v-for="student in students" :key="student._id">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="selected" :value="student.number">
                {{ student.name }}
              </label>
            </div>
        </div>
        <br>
        <div class="form-group">
            <span for="">نوع المشكلة &nbsp;</span>
            <span id="mm">
                <input type="text" v-model="type" v-if="ntype" placeholder="اكتب هنا...">
                <select class="form-controla bg-white" v-if="!ntype" @change="ccc()" v-model="type" required="">
                    <option value="تعليمية">تعليمية</option>
                    <option value="نفسية">نفسية</option>
                    <option value="اجتماعية">اجتماعية</option>
                    <option value="سلوكية">سلوكية</option>
                    <option value="">أخرى.. اكتب</option>
                </select>
            </span>
        </div>
        <div class="form-group">
        <span for="">تكرار المشكلة &nbsp;</span>
            <select class="f bg-white" v-model="repeat" id="">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
            </select>
        </div>
        <div class="form-group">
            <p for="">وصف المشكلة كاملة (اختياري)</p>
            <textarea class="form-control" v-model="details" id="" placeholder="اكتب هنا... (اختياري)" rows="3"></textarea>
        </div>
        <div class="form-group">
            <p for="">الاجراء المتخذ (اختياري)</p>
            <textarea class="form-control" v-model="opinion" id="" placeholder="اكتب هنا... (اختياري)" rows="3"></textarea>
        </div>
        <div class="row">
            <div class="col-12 col-lg-6 text-center g c">
                <button class="btn btn-primary btn-block" @click="done()">
                    <i class="fa fa-check"></i>
                    تحويل {{ admin ? 'للموجه' : 'للوكيل' }} الآن
                </button>
            </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, VBModal, BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BFormCheckbox,
        BModal
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            teacher: JSON.parse(localStorage.getItem('teacher')),
            items: [],
            q: "",
            students: [],
            selected: [],
            type: "تعليمية",
            details: "",
            opinion: "",
            repeat: "1",
            admin: false,
            ntype: false
        }
    },
    created(){ 
        this.getFo();
        this.getStudents();
    },
    methods: {
        remove(r){
            if(confirm("متأكد من الحذف؟")){
                var g = this;
                $.post(api + '/teacher/forward/delete', {
                    username: this.teacher.username,
                    password: this.teacher.password,
                    id: r
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response)
                    }else{
                        g.getFo()
                    }
                }).fail(function(){
                })
            }
        },
        getStudents(){
            var g = this;
            $.post(api + '/teacher/mystudents', {
                username: this.teacher.username,
                password: this.teacher.password,
                q: this.q
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.students = r.response;
                }
            }).fail(function(){
            })
        },
        ccc(){
            if(this.type == ""){
                this.ntype = true;
            }
        },
        done(){
            var g = this;
            if(this.admin == 'yes'){
                if(!confirm("متأكد من التحويل للموجه مباشرة؟")){return;}
            }
            $.post(api + '/teacher/forward', {
                username: this.teacher.username,
                password: this.teacher.password,
                type: this.type,
                opinion: this.opinion,
                details: this.details,
                students: this.selected,
                repeat: this.repeat,
                admin: this.admin
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    $("#new___BV_modal_header_ > button").click();
                    g.getFo()
                }
            }).fail(function(){
            })
        },
        getFo(){
            var g = this;
            $.post(api + '/teacher/forward/list', {
                username: this.teacher.username,
                password: this.teacher.password
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.items = r.response;
                }
            }).fail(function(){
            })
        }
    }
}
</script>

<style>
.classroom{
    padding: 15px; 
    border: 2px solid #ddd;
    border-radius: 5px;
}
.classroom:hover{
    background: #fafafa !important;
    cursor: pointer;
    border: 2px solid #333;
}
</style>